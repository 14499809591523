<template>
  <!--  -->
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="header-search">
      <el-form
          ref="form"
          inline
          :model="searchData"
          label-width=""
      >
        <el-form-item label="订单号">
          <el-input
              size="small"
              v-model="searchData.order_no"
              placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机号码">
          <el-input
              size="small"
              v-model="searchData.mobile"
              placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="支付方式">
          <el-select
              style="width: 200px"
              v-model="searchData.pay_type"
              placeholder="请选择"
              size="mini"
              clearable
          >
            <el-option
                label="微信支付"
                :value="20"
            ></el-option>
            <el-option
                label="微信扫码支付"
                :value="40"
            ></el-option>
            <el-option
                label="支付宝扫码支付"
                :value="50"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商品名称">
          <el-input
              size="small"
              v-model="searchData.product_name"
              placeholder="请输入标题"
          ></el-input>
        </el-form-item>
        <el-form-item label="员工">
          <el-cascader
              v-model="searchData.agent_employee_ids"
              :options="agentEmployeeTreeData"
              :show-all-levels="false"
              clearable
              size="small"
          ></el-cascader>
        </el-form-item>
        <el-form-item label=" ">
          <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="getList"
          >搜索
          </el-button>
          <el-button
              type="info"
              size="mini"
              icon="el-icon-refresh"
              @click="reset"
          >重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="tabs">
      <div
          :class="['item', searchData.pay_status === null ? 'active' : '']"
          @click="handleClickTab(null)"
      >
        <el-badge>
          <span class="tab-name">全部</span>
        </el-badge>
      </div>
      <div
          :class="['item', searchData.pay_status === 10 ? 'active' : '']"
          @click="handleClickTab(10)"
      >
        <el-badge>
          <span class="tab-name">待支付</span>
        </el-badge>
      </div>
      <div
          :class="['item', searchData.pay_status === 20 ? 'active' : '']"
          @click="handleClickTab(20)"
      >
        <el-badge>
          <span class="tab-name">已支付</span>
        </el-badge>
      </div>
    </div>
    <el-table :data="list">
      <el-table-column
          prop="order_no"
          label="订单编号"
          width="width"
          min-width="40px"
      >
      </el-table-column>
      <el-table-column
          prop="total_price"
          label="订单金额"
          width="width"
          min-width="40px"
      >
        <template slot-scope="{ row }">
          ￥{{ row.pay_price }} 元
        </template>
      </el-table-column>
      <el-table-column
          prop="pay_type_dsc"
          label="支付方式"
          width="width"
          min-width="40px"
      >
      </el-table-column>
      <el-table-column
          prop="pay_status_dsc"
          label="付款状态"
          width="width"
          min-width="40px"
      >
        <template slot-scope="{ row }">
          <el-tag
              size="mini"
              :type="row.pay_status === 20 ? 'success' : 'danger'"
          >{{ row.pay_status_dsc }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="product_info"
          label="商品信息"
          width="width"
          min-width="60px"
      >
        <template slot-scope="{ row }">
          <div class="user-box">
            <div class="row" v-if="row.product_id">
              <span>商品ID：</span>
              <span>{{ row.product_id }}</span>
            </div>
            <div class="row">
              <span>商品名称：</span>
              <span>{{ row.product_name }}</span>
            </div>
            <div class="row" v-if="row.product_id">
              <span>商品价格：</span>
              <span>￥{{ row.price }}元/{{ row.year }}</span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          prop="product_info"
          label="用户信息"
          width="width"
          min-width="60px"
      >
        <template slot-scope="{ row }">
          <div
              class="user-box"
              v-if="row.user"
          >
            <div class="row">
              <span>用户ID：</span>
              <el-link
                  type="primary"
                  @click="$router.push({ name: 'CustomerList', query: { id: row.user.id } })"
              >{{ row.user.id }}
              </el-link>
            </div>
            <div class="row">
              <span>用户昵称：</span>
              <span>{{ row.user?.nickname }}</span>
            </div>
            <div class="row">
              <span>手机号码：</span>
              <span>{{ row.user?.mobile }}</span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          prop="agent_employee"
          label="员工信息"
          width="width"
          min-width="60px"
      >
        <template slot-scope="{ row }">
          <div
              class="user-box"
              v-if="row.agent_employee"
          >
            <div class="row">
              <span>用户ID：</span>
              <el-link
                  type="primary"
                  @click="$router.push({ name: 'CustomerList', query: { id: row.user.id } })"
              >{{ row.agent_employee.id }}
              </el-link>
            </div>
            <div class="row">
              <span>姓名：</span>
              <span>{{ row.agent_employee?.account }}</span>
            </div>
            <div class="row">
              <span>手机号码：</span>
              <span>{{ row.agent_employee?.mobile }}</span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column
          prop="pay_date"
          label="支付时间"
          width="width"
          min-width="40px"
      ></el-table-column>
      <el-table-column
          prop="created_at"
          label="创建日期"
          width="width"
          min-width="40px"
      ></el-table-column>
      <el-table-column
          label="操作"
          width="150px"
          fixed="right"
      >
        <template slot-scope="{ row }">
          <el-link
              style="margin: 0 10px"
              type="primary"
              :underline="false"
              @click="handleDetail(row)"
          >详情
          </el-link>

          <!-- <el-link
            type="primary"
            :underline="false"
            @click="handleDel(row)"
          >删除</el-link> -->
        </template>
      </el-table-column>
    </el-table>
    <pagination
        :total="total"
        :page.sync="searchData.page"
        :limit.sync="searchData.pagesize"
        @pagination="getList"
    />
    <!-- 详情弹窗 -->
    <el-drawer
        size="45%"
        :visible.sync="show_detail"
        direction="rtl"
    >
      <Detail :detail="detail"></Detail>
    </el-drawer>
  </div>
</template>

<script>
import {getListAPI, delAPI, addAPI, editAPI, getDetailAPI, changeStatusAPI} from './api'
import {authBtnMixin} from '@/mixins/authBtnMixin'
import Detail from './detail.vue'
import {getAgentEmployeeTreeAPI} from "@/views/user/api";

export default {
  name: 'Index',
  mixins: [authBtnMixin],
  components: {Detail},
  data() {
    return {
      rules: [{required: true, message: '该项不能为空', trigger: 'blur'}],
      show_detail: false,
      show_review: false,
      list: [],
      city_list: [],

      agentEmployeeTreeData: [],
      searchData: {
        order_type: 10,
        page: 1,
        pagesize: 10,
        order_no: '',
        mobile: '',
        product_name: '',
        agent_employee_ids: [],
        pay_type: null,
        pay_status: null
      },
      total: 0,
      changeStatusData: {
        id: '',
        status: null
      }
    }
  },
  provide() {
    return {
      context: this
    }
  },
  mounted() {
    this.getList()
    this.getAgentEmployeeTree()
  },

  methods: {
    async getAgentEmployeeTree() {
      // 获取get 参数
      this.agentEmployeeTreeData = await getAgentEmployeeTreeAPI()
    },
    // 详情
    async handleDetail({id}) {
      this.detail = await getDetailAPI(id)
      this.show_detail = true
    },
    handleClickTab(val) {
      if (this.searchData.pay_status == val) return
      this.searchData.pay_status = val
      this.getList()
    },
    // 审核弹窗
    getStatus(row) {
      this.changeStatusData = {
        id: row.id,
        status: row.status
      }
      this.show_review = true
    },
    changeStatus() {
      changeStatusAPI(this.changeStatusData).then(() => {
        this.$message.success('提交成功')
        this.getList()
        this.show_review = false
      })
    },
    async getList() {
      // 获取get 参数
      let params = {...this.searchData}
      const res = await getListAPI(params)
      this.list = res.data || []
      this.total = res.total || 0
    },
    // del
    async handleDel({id}) {
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            delAPI(id).then(() => {
              this.$message.success('删除成功')
              this.getList()
            })
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            })
          })
    },
    // 新增
    handleAdd() {
      this.show_update = true
      this.$nextTick(() => {
        this.formData = {
          name: '',
          icon: '',
          describe: ''
        }
        this.$refs.form.resetFields()
      })
    },
    // edit
    async handleEdit({id}) {
      this.show_update = true
      this.$nextTick(async () => {
        this.formData = await getDetailAPI(id)
        this.$refs.form.resetFields()
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success('编辑成功')
              this.getList()
              this.show_update = false
            })
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success('添加成功')
              this.getList()
              this.show_update = false
            })
          }
        }
      })
    },
    reset() {
      Object.assign(this._data.searchData, this.$options.data().searchData)
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;

  .header-search {
    .el-form /deep/ {
      .el-form-item {
        margin-right: 35px;
      }
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #dcdfe6;

    .item {
      cursor: pointer;
      position: relative;
      padding-bottom: 5px;
      margin-right: 20px;
      // border-bottom: 2px solid #409eff;

      .tab-name {
        padding: 6px;
      }
    }

    .active {
      color: #409eff;
      font-weight: 600;

      &::after {
        content: '';
        display: inline-block;
        height: 2px;
        width: 25px;
        background: #409eff;
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }

  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }

  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
    margin-right: 10px;
  }

  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }

  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }

  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }

  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 70px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.icon-box /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 108px;
    height: 108px;
    line-height: 108px;
    text-align: center;
  }

  .avatar {
    width: 108px;
    height: 108px;
    display: block;
  }
}

.user-box {
  .row {
    display: flex;

    span {
      &:nth-child(1) {
        width: 72px;
        text-align: right;
      }

      &:nth-child(2) {
        text-align: left;
      }
    }
  }
}
</style>
